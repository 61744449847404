(function( $ ) {

    /**
     * Appelle le service DQE à partir du champ de saisie d'un prénom
     * Options disponibles :
     *   - server    : URL du serveur ou "jsonp" si on souhaite fonctionner en JSONP (sans passer
     *   - license   : uniquement si l'on souhaite fonctionner en JSONP
     *   - civilities: [optionnel] Dictionnaire des civilités sous la forme {value0: 'M' ou 'F', value1: 'M' ou 'F'...}
     *   - civility  : [optionnel] Sélecteur jquery vers le champ civilité
     *
     *   Evènements disponibles
     *   - suggest(selectedFirstname) : Se déclenche dès qu'un élément de la liste suggérée est choisi
     *   - genderError(TitleFieldGender, FirstnameGender) : Se déclenche dès que le prénom saisi n'est pas du même sexe que la civilité
     *   - validateError() : Se déclenche dès que le prénom saisi est inconnu dans la base
     *   - change : se déclenche dès qu'un changement de civilité ou de prénom a lieu (permet par exemple de remettre le statut à vide)

     *
     * @param {object} options Tableau associatif des options
     * @returns {jQuery}
     */
    $.fn.dqefirstname = function(options) {

        var myDQE = this;

        var settings = $.extend({
            //Paramètres par défaut
            license: ''
        }, options);
        myDQE.settings = settings;

        //On récupère les champs à partir de leur selecteur
        myDQE.server     = settings.server;
        myDQE.license    = settings.license;
        myDQE.asmx       = myDQE.server.toLowerCase().indexOf(".asmx") > -1;
        myDQE.civilities = settings.civilities ? settings.civilities : {};
        myDQE.civility   = settings.civility ? $(settings.civility) : false;

        //Paramètres d'appel AJAX par défaut pour .net
        if (myDQE.asmx) {
            $.ajaxSetup({
                type: "POST",
                contentType: "application/json; charset=utf-8",
                data: "{}",
                processData: false,
                dataFilter: function(data) {
                    if (typeof (JSON) !== 'undefined' && typeof (JSON.parse) === 'function') data = JSON.parse(data);
                    else data = eval('(' + data + ')');
                    if (data.hasOwnProperty('d')) return data.d;
                    return data;
                }
            });

            $.ajaxPrefilter(function(options, originalOptions) {
                options.data = JSON.stringify(originalOptions.data);
            });
        }

        if (myDQE.server == 'jsonp') {
            myDQE.ajax = function(url, callback) {
                $.ajax({
                    url: url,
                    dataType: 'jsonp',
                    jsonp: 'callback',
                    success: function(data) {
                        callback(JSON.parse(data));
                    }
                });
            };
        }
        else {
            myDQE.ajax = function(url, callback) {
                $.ajax({
                    url: myDQE.server,
                    data: {url: url},
                    method: 'POST',
                    dataType: 'json',
                    success: function(data) {
                        callback(data);
                    }
                });
            };
        }

        myDQE.remove_accents = function(s) {
            var ko = 'ÀÁÂÃÄÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÇçàáâãèéêëîïòôõöùúûüñ';
            var ok = 'AAAAAEEEEIIIIOOOOOUUUUCcaaaaeeeeiioooouuuun';
            var len = s.length;
            var p;
            var c;
            var result = "";
            for (var i = 0; i < len; i++) {
                c = s.charAt(i);
                p = ko.indexOf(c);
                result += p == -1 ? c : ok.charAt(p);
            }
            return result;
        };

        myDQE.url = function(data) {
            var parameters = [];
            for (var key in data) {
                if (!data.hasOwnProperty(key) || key == "fn" || key == "server") continue;
                parameters.push(key + "=" + encodeURIComponent(data[key]));
            }

            return 'https://prod2.dqe-software.com/' + data["fn"] + "/?" + parameters.join("&") + "&Licence=" + myDQE.license;
        };

        myDQE.removeAutocomplete = function(element) {
            if (element.data('ui-autocomplete')) {
                element.autocomplete("destroy");
                element.removeData("ui-autocomplete");
            }
        };

        myDQE.getCivilityGender = function() {
            var result = '';
            if (myDQE.civility && myDQE.civilities) result = myDQE.civilities[myDQE.civility.val()];
            if (result != 'M' && result != 'F') result = '';
            return result;
        };

        myDQE.checkName = function() {
            myDQE.trigger('change');
            var firstname = myDQE.val();
            if (!firstname) return;
            var url = myDQE.url({fn: "FIRSTNAMESUGGEST", Firstname: firstname, NBResponse: 10, Verifname: 'Y'});
            myDQE.ajax(url, function(data) {
                if (!data || !data['suggest']) return [];
                var result = data['suggest'];
                var firstname_gender = result.length > 0 ? result[0][0] : false;

                if (firstname_gender) {
                    //Prénom connu : on compare les genres de la civilité et du prénom
                    var civility_gender = myDQE.getCivilityGender();
                    if (firstname_gender != 'I' && civility_gender && firstname_gender != civility_gender) {
                        myDQE.trigger('genderError', [civility_gender, firstname_gender]);
                    }
                }
                else myDQE.trigger('validateError', [firstname]); //Prénom inconnu
            });
        };

        myDQE.removeAutocomplete(myDQE);
        myDQE.autocomplete({
            source: function(request, response) {
                window.genders = {};
                var input = myDQE.remove_accents(request.term);
                var params = {fn: "FIRSTNAMESUGGEST", Firstname: input, NBResponse: 10};
                var gender = myDQE.getCivilityGender();
                if (gender) params['Gender'] = gender;
                var url = myDQE.url(params);
                myDQE.ajax(url, function(data) {
                    if (!data || !data['suggest']) return [];
                    var result = data['suggest'];
                    var answers = [];
                    for (var i = 0, len = result.length; i < len; i++) {
                        answers.push(result[i][1]);
                        window.genders[result[i][1]] = result[i][0];
                    }
                    response(answers);
                });
            },
            minLength: 1,
            delay: 0,
            open: function(event, ui) {
                $('.ui-autocomplete').css('max-height', '150px').css('overflow-y', 'auto').css('overflow-x', 'hidden');
            },
            select: function(event, ui) {
                myDQE.trigger('change');
                myDQE.trigger('suggest', ui.item);
                var name_gender = window.genders[ui.item.value];
                if (myDQE.civility && myDQE.civilities && name_gender != 'I') {
                    var civility_gender = myDQE.civilities[myDQE.civility.val()];
                    if (name_gender != 'I' && name_gender != civility_gender) {
                        myDQE.trigger('genderError', [civility_gender, name_gender]);
                    }
                }
                return true;
            },
            focus: function(event, ui) {
                event.preventDefault();
            }
        }).focus(function() {
            if ($(this).data('ui-autocomplete')) $(this).autocomplete("search", $(this).val());
        });

        myDQE.on("blur", function() {
            myDQE.checkName();
        });

        myDQE.on("input", function() {
            myDQE.trigger('change');
        });

        if (myDQE.civility) {
            myDQE.civility.on("input", function() {
                myDQE.checkName();
            });
        }

        return myDQE;
    };

}( jQuery ));
