(function( $ ) {

    /**
     * Appelle le service DQE pour rechercher des entreprises
     * Options disponibles :
     *   - server: URL du serveur
     *   - country: code d'un pays précis (3 caractères) OU sélecteur jQuery pointant sur le champ pays
     *   - ac_zip: sélecteur jquery vers le champ ZIP (nécessaire si autocomplete du champ société)
     *   - ac_company: sélecteur jquery vers le champ société (nécessaire si autocomplete du champ société)
     *   - ac_callback: nom de la fonction de callback à, appeler avec toutes les informations relatives à la société sélectionnée (nécessaire si autocomplete du champ société)
     *
     * Méthodes disponibles :
     *   - by_name(company_name, zip, callback_function_name): Recherche des entreprises par nom et code postal
     *   - by_number(company_number, callback_function_name): Recherche des entreprises par numéro

     *
     * @param {object} options Tableau associatif des options
     * @returns {jQuery}
     */
    $.fn.dqeb2b = function(options) {

        var myDQE = this;
        myDQE.server       = options.server;
        myDQE.license      = options.license ? options.license : '';
        myDQE.ac_zip       = options.ac_zip ? $(options.ac_zip) : false;
        myDQE.ac_company   = options.ac_company ? $(options.ac_company) : false;
        myDQE.ac_callback  = options.ac_callback ? options.ac_callback : false;
        myDQE.country      = options.country ? options.country : 'FRA';
        myDQE.countryField = myDQE.country.length == 3 ? false : $(myDQE.country);

        myDQE.asmx = myDQE.server.toLowerCase().indexOf(".asmx") > -1;

        //Paramètres d'appel AJAX par défaut pour .net
        if (myDQE.asmx) {
            $.ajaxSetup({
                type: "POST",
                contentType: "application/json; charset=utf-8",
                data: "{}",
                processData: false,
                dataFilter: function(data) {
                    if (typeof (JSON) !== 'undefined' && typeof (JSON.parse) === 'function') data = JSON.parse(data);
                    else data = eval('(' + data + ')');
                    if (data.hasOwnProperty('d')) return data.d;
                    return data;
                }
            });

            $.ajaxPrefilter(function(options, originalOptions, jqXHR) {
                options.data = JSON.stringify(originalOptions.data);
            });
        }

        if (myDQE.server == 'jsonp') {
            myDQE.ajax = function(url, callback) {
                $.ajax({
                    url: url,
                    dataType: 'jsonp',
                    jsonp: 'callback',
                    success: function(data) {
                        callback(JSON.parse(data));
                    }
                });
            };
        }
        else {
            if (myDQE.server === 'cors') {
                myDQE.ajax = function(url, callback) {
                    $.ajax({
                        url: url,
                        method: 'GET',
                        dataType: 'text',
                        crossdomain: true,
                        success: function(data) {
                            callback(JSON.parse(data));
                        }
                    });
                };
            }
            else {
                myDQE.ajax = function (url, callback) {
                    $.ajax({
                        url: myDQE.server,
                        data: {url: url},
                        method: 'POST',
                        dataType: 'json',
                        success: function (data) {
                            callback(data);
                        },
                        error: function () {
                            console.log("Le JSON renvoyé est incorrect");
                            callback({});
                        }
                    });
                };
            }
        }

        /**
         * Renvoie le pays sélectionné ou le pays par défaut spécifié dans les options
         * @returns {string}
         */
        myDQE.selected_country = function(zip) {
            if (!zip) zip = "";
            var suffix = zip.length == 5 ? "_RT" : "";
            if (myDQE.countryField) return myDQE.countryField.val() + suffix;
            return myDQE.country + suffix;
        };
        
        myDQE.b2b_ajax_call = function(company_name, zip, company_number, callback_function_name) {
            var url;
            if (company_number) url = myDQE.url({fn: "RECSIRET", CompanyNumber: company_number, Dictionary: myDQE.dictionary, Country: myDQE.selected_country()});
            else url = myDQE.url({fn: "RECSIRET", CompanyName: company_name, ZIP_Code: zip, Dictionary: 'Y', Country: myDQE.selected_country(zip)});
            myDQE.ajax(url, function(result) {
                
                if (!result || !result['DATA1']) {
                    if (myDQE.dictionary == 'CONTACTS') window[callback_function_name](result);
                    else window[callback_function_name]([]);
                    return;
                }

                var i = 1;
                var line;
                var response = [];
                while (result['DATA' + i]) {
                    line = result['DATA' + i];
                    response.push({name: line['CompanyName'], zip: line['ZIP_Code'], city: line['Locality'], ref: line['SEARCH_REF']});
                    i++;
                }
                window[callback_function_name](response);
            });
        };

        myDQE.by_name = function(company_name, zip, callback_function_name) {
            myDQE.dictionary = 'Y';
            myDQE.b2b_ajax_call(company_name, zip, '', callback_function_name);
        };

        myDQE.by_number = function(company_number, callback_function_name) {
            myDQE.dictionary = 'Y';
            myDQE.b2b_ajax_call('', '', company_number, callback_function_name);
        };

        myDQE.by_number_contacts = function(company_number, callback_function_name) {
            myDQE.dictionary = 'CONTACTS';
            myDQE.b2b_ajax_call('', '', company_number, callback_function_name);
        };
        
        myDQE.infos = function(company_id, callback_function_name) {
            var country = myDQE.selected_country();
            var url = myDQE.url({fn: "SIRETINFO", SEARCH_REF: company_id, Dictionary: 'Y', Country: country});
            myDQE.ajax(url, function(result) {
                var data = result && result['DATA1'] ? result['DATA1'] : {};
                window[callback_function_name](data);
            });
        };

        myDQE.url = function(data) {
            var parameters = [];
            for (var key in data) {
                if (!data.hasOwnProperty(key) || key == "fn" || key == "server") continue;
                parameters.push(key + "=" + encodeURIComponent(data[key]));
            }
            return 'https://prod2.dqe-software.com/' + data["fn"] + "/?" + parameters.join("&") + "&Licence=" + myDQE.license;
        };
        
        //On active l'autocomplete du champ société
        if (myDQE.ac_company) {
            myDQE.ac_company.autocomplete({
                source: function(request, response) {
                    var zip = myDQE.ac_zip.val();
                    var country = myDQE.selected_country(zip);
                    console.log("country:", country);
                    //if (country !== 'FRA_RT') return;
                    var url = myDQE.url({fn: "RECSIRET", CompanyName: request.term, ZIP_Code: zip, Dictionary: 'Y', Country: country});
                    myDQE.ajax(url, function(data) {
                        var i = 1;
                        var results = [];
                        var line, label;
                        while (data['DATA' + i]) {
                            line = data['DATA' + i];
                            label = line.CompanyName + " (" + line.ZIP_Code + " " + line.Locality + ")";
                            results.push({label: label, value: line.CompanyName, sr: line.SEARCH_REF});
                            i++;
                        }
                        response(results);
                    });
                },
                minLength: 2,
                open: function(event, ui) {
                    $('.ui-autocomplete').css('max-height', '150px').css('overflow-y', 'auto').css('overflow-x', 'hidden');
                },
                select: function(event, ui) {
                    if (myDQE.ac_callback) {
                        myDQE.infos(ui.item.sr, myDQE.ac_callback);
                    }
                    return true;
                },
                focus: function(event, ui) {
                    event.preventDefault();
                }
            }).on("click", function () {
                var value = myDQE.ac_company.val();
                if ($(this).data('ui-autocomplete') && value.length > 2) $(this).autocomplete("search", value);
            });
            myDQE.ac_company.click();
        }

        return myDQE;
    };

}( jQuery ));
