/**
 * Created by abde on 06/11/17.
 */
var wsDqeActions = {};
var myDQE= {};

wsDqeActions.init = function (wsDqe) {
    this.wsDqe = wsDqe;
    this.$elements = this.wsDqe.getElements();
    this.listeners();
};
var callback_rnvp = function(data) {};

wsDqeActions.checkAddress = function (callback) {
    var $elements = this.$elements;
    if($elements.address.$street.val() !== '' || $elements.address.$compl.val() !=='') {
        var address = {
            compl  : $elements.address.$compl.val(),
            street : $elements.address.$street.val(),
            zip    : $elements.address.$zip.val(),
            city   : $elements.address.$city.val(),
            country: $elements.address.$country.val()
        };

        callback_rnvp = function(data) {
            if(data.data === 10) {
                if (typeof(data.corrections) !== 'undefined'){
                    var corrections = data.corrections;
                    if(typeof(corrections.city) !== 'undefined') $elements.address.$city.val(corrections.city);
                    if(typeof(corrections.street) !== 'undefined') $elements.address.$street.val( corrections.street);
                    if(typeof(corrections.zip) !== 'undefined') $elements.address.$zip.val(corrections.zip);
                }
                callback(data, true);
            } else {
                callback(data, false);
            }
        };

        window.dqeObject.check('callback_rnvp', address);
    } else {
        callback({}, true);
    }

};

wsDqeActions.checkEmail = function (callback) {
    var $elements= this.$elements;
    var clean_mail = $elements.$email.val();
    var parameters = {fn: "DQEEMAILLOOKUP", Email: encodeURIComponent(clean_mail)};
    var url = myDQE.url(parameters);
    myDQE.ajax(url, function(data) {
        var ko = {code: 99, msg: 'Le domaine ne répond pas', state: 'error'};
        if (!data || !data[1]) return ko;

        var code = data[1]['IdError'];
        var parts = clean_mail.split('@');
        var messages = {
            '91': "Erreur de syntaxe",
            '92': "Domaine " + parts[1] + " inconnu",
            '93': "Domaine " + parts[1] + " en blacklist",
            '94': "Nom d'utilisateur non autorisé (nom réservé ou interdit)",
            '95': "Adresse e-mail temporaire jetable",
            '99': 'Le domaine ne répond pas',
            '04': "E-mail non fourni",
            '03': "Boîte de réception pleine",
            '02': "Adresse e-mail non trouvée sur le domaine " + parts[1],
            '01': "E-mail correct mais le nom " + parts[0] + " n'a pas pu être contrôlé",
            '00': "E-mail valide"
        };

        var states = {'91': 'error', '92': 'warning', '93': 'error', '94': 'warning', '99': 'error', '04': 'error', '03': 'error', '02': 'warning', '01': 'warning', '00': 'success', '95': 'error'};
        if (!states[code]) code = '01';
        var response = {code: code, msg: messages[code], state: states[code]};

        if(states[code] === 'success' || states[code] === 'warning') callback(response, true); else callback(response, false);
    });
};

wsDqeActions.checkPhone = function(callback){
    var self = this;
    var $phone = this.$elements.$phone.filter(function() {
        return this.value;
    });
    var count = $phone.length;
    var errors = [];

    var finishCheckPhone = function () {
        callback(errors);
    };

    var callAjax = function (index, $phone, valPhone, url) {
        if(valPhone === '') {
            if(index === (count-1)){
                finishCheckPhone(errors);
            }
        } else {
            var afterAjax = function(data) {
                data = data[1];
                var ok = parseInt(data['IdError'], 10);
                var ko =  {status: 0, state: 'error', response: 0};
                var response = ok ? {status: 1, state: 'success', response: data.IdError} :  ko;
                if(response.state !== 'success') {
                    errors.push({$phone: $phone, error: 'Numéro de téléphone '+$phone.attr('data-phone-type')+': Non correct!'});
                }
                if(index === (count-1)){
                    finishCheckPhone(errors);
                }
            };

            myDQE.ajax(url, afterAjax);
        }
    };

    for (var i = 0; i < count; i++) {
        var $currentPhone =  $($phone[i]);
        var valPhone = $currentPhone.val();
        var parameters = {fn: "TEL", "Tel": encodeURIComponent(valPhone), "Format": 0, "Pays": myDQE.convert_iso2(self.$elements.$country.val()) };
        var url = myDQE.url(parameters);
        callAjax(i, $currentPhone, valPhone, url);
    }
    if(count === 0) finishCheckPhone(errors);
};

wsDqeActions.listeners = function () {
    var self = this;
    var afterCheck = {};
    var listErrors = [];
    var finishChecking = function () {
      if(listErrors.length >0) {
        self.openPopup(listErrors);
      }  else {
          $('.submit-input').click();
      }
    };
    afterCheck.address = function (data, success) {
        if(!success) {
            listErrors.push('Adresse: '+data.label);
            self.$elements.address.$compl.closest('div').addClass('has-error');
            self.$elements.address.$zip.closest('div').addClass('has-error');
            self.$elements.address.$street.closest('div').addClass('has-error');
            self.$elements.address.$city.closest('div').addClass('has-error');
            self.$elements.address.$country.closest('div').addClass('has-error');
        }
        self.checkEmail(afterCheck.email);
    };

    afterCheck.email = function (data, success) {
        if(!success) {
            self.$elements.$email.closest('div').addClass('has-error');
            listErrors.push('Email: '+data.msg);
        }
        self.checkPhone(afterCheck.phone);
    };

    afterCheck.phone = function (errors) {
        if(errors.length > 0) {
            errors.forEach(function(element) {
                element.$phone.closest('div').addClass('has-error');
                listErrors.push(element.error);
            });
        }
        finishChecking();
    };

    $(document).on('click', '#btn_check_my_profile', function () {
        var $btn = $(this);
        var $address = $('#profile_adresse');
        $address.val($address.val().replace("[","").replace("]",""));
        if($btn.hasClass('is-loading')) return;
        $btn.addClass('is-loading');
        listErrors = [];
        // remove all error classes
        self.$elements.address.$compl.closest('div').removeClass('has-error');
        self.$elements.address.$zip.closest('div').removeClass('has-error');
        self.$elements.address.$street.closest('div').removeClass('has-error');
        self.$elements.address.$city.closest('div').removeClass('has-error');
        self.$elements.address.$country.closest('div').removeClass('has-error');
        self.$elements.$phone.closest('div').removeClass('has-error');
        self.$elements.$email.closest('div').removeClass('has-error');
        self.checkAddress(afterCheck.address);
    });

    $(document).on('click', '.ignore-profil-errors',function () {
      var $profilForm = $('#ws-form-profile');
      if($profilForm.length >0) $profilForm.submit();
    });

    $(document).on('click' , '.close-popup', function () {
        self.closPopup();
        $('#btn_check_my_profile').removeClass('is-loading');
    });
};

wsDqeActions.openPopup = function (errors) {
    var htmlList = "<ul>";
    errors.forEach(function (element) {
        htmlList += '<li>'+element+'</li>';
    });
    htmlList += "</ul>";
    $('.popup__container').html(htmlList);
  $('.popup__background').show();
  $('.popup').show();

};

wsDqeActions.closPopup = function () {
    $('.popup__background').hide();
    $('.popup').hide();
};

myDQE.ajax = function(url, callback) {
    $.ajax({
        url: '/ajax/dqe',
        data: {url: url},
        method: 'POST',
        success: function(data) {
            callback(data);
        },
        error: function (jqXHR, textStatus, errorThrown) {
            var response = {code: 99, msg: 'Service non disponible', state: 'error'};
            callback(response);
        }
    });
};

myDQE.url = function(data) {
    var parameters = [];
    for (var key in data) {
        if (!data.hasOwnProperty(key) || key === "fn" || key === "server") continue;
        parameters.push(key + "=" + encodeURIComponent(data[key]));
    }

    return 'https://prod2.dqe-software.com/' + data["fn"] + "/?" + parameters.join("&") + "&Licence=";
};

myDQE.convert_iso2 = function(country) {
    var table = {
        AF:'AFG',AX:'ALA',AL:'ALB',DZ:'DZA',AS:'ASM',AD:'AND',AO:'AGO',AI:'AIA',AQ:'ATA',AG:'ATG',AR:'ARG',AM:'ARM',AW:'ABW',
        AU:'AUS',AT:'AUT',AZ:'AZE',BS:'BHS',BH:'BHR',BD:'BGD',BB:'BRB',BY:'BLR',BE:'BEL',BZ:'BLZ',BJ:'BEN',BM:'BMU',BT:'BTN',
        BO:'BOL',BA:'BIH',BW:'BWA',BV:'BVT',BR:'BRA',VG:'VGB',IO:'IOT',BN:'BRN',BG:'BGR',BF:'BFA',BI:'BDI',KH:'KHM',CM:'CMR',
        CA:'CAN',CV:'CPV',KY:'CYM',CF:'CAF',TD:'TCD',CL:'CHL',CN:'CHN',HK:'HKG',MO:'MAC',CX:'CXR',CC:'CCK',CO:'COL',KM:'COM',
        CG:'COG',CD:'COD',CK:'COK',CR:'CRI',CI:'CIV',HR:'HRV',CU:'CUB',CY:'CYP',CZ:'CZE',DK:'DNK',DJ:'DJI',DM:'DMA',DO:'DOM',
        EC:'ECU',EG:'EGY',SV:'SLV',GQ:'GNQ',ER:'ERI',EE:'EST',ET:'ETH',FK:'FLK',FO:'FRO',FJ:'FJI',FI:'FIN',FR:'FRA',GF:'GUF',
        PF:'PYF',TF:'ATF',GA:'GAB',GM:'GMB',GE:'GEO',DE:'DEU',GH:'GHA',GI:'GIB',GR:'GRC',GL:'GRL',GD:'GRD',GP:'GLP',GU:'GUM',
        GT:'GTM',GG:'GGY',GN:'GIN',GW:'GNB',GY:'GUY',HT:'HTI',HM:'HMD',VA:'VAT',HN:'HND',HU:'HUN',IS:'ISL',IN:'IND',ID:'IDN',
        IR:'IRN',IQ:'IRQ',IE:'IRL',IM:'IMN',IL:'ISR',IT:'ITA',JM:'JAM',JP:'JPN',JE:'JEY',JO:'JOR',KZ:'KAZ',KE:'KEN',KI:'KIR',
        KP:'PRK',KR:'KOR',KW:'KWT',KG:'KGZ',LA:'LAO',LV:'LVA',LB:'LBN',LS:'LSO',LR:'LBR',LY:'LBY',LI:'LIE',LT:'LTU',LU:'LUX',
        MK:'MKD',MG:'MDG',MW:'MWI',MY:'MYS',MV:'MDV',ML:'MLI',MT:'MLT',MH:'MHL',MQ:'MTQ',MR:'MRT',MU:'MUS',YT:'MYT',MX:'MEX',
        FM:'FSM',MD:'MDA',MC:'MCO',MN:'MNG',ME:'MNE',MS:'MSR',MA:'MAR',MZ:'MOZ',MM:'MMR',NA:'NAM',NR:'NRU',NP:'NPL',NL:'NLD',
        AN:'ANT',NC:'NCL',NZ:'NZL',NI:'NIC',NE:'NER',NG:'NGA',NU:'NIU',NF:'NFK',MP:'MNP',NO:'NOR',OM:'OMN',PK:'PAK',PW:'PLW',
        PS:'PSE',PA:'PAN',PG:'PNG',PY:'PRY',PE:'PER',PH:'PHL',PN:'PCN',PL:'POL',PT:'PRT',PR:'PRI',QA:'QAT',RE:'REU',RO:'ROU',
        RU:'RUS',RW:'RWA',BL:'BLM',SH:'SHN',KN:'KNA',LC:'LCA',MF:'MAF',PM:'SPM',VC:'VCT',WS:'WSM',SM:'SMR',ST:'STP',SA:'SAU',
        SN:'SEN',RS:'SRB',SC:'SYC',SL:'SLE',SG:'SGP',SK:'SVK',SI:'SVN',SB:'SLB',SO:'SOM',ZA:'ZAF',GS:'SGS',SS:'SSD',ES:'ESP',
        LK:'LKA',SD:'SDN',SR:'SUR',SJ:'SJM',SZ:'SWZ',SE:'SWE',CH:'CHE',SY:'SYR',TW:'TWN',TJ:'TJK',TZ:'TZA',TH:'THA',TL:'TLS',
        TG:'TGO',TK:'TKL',TO:'TON',TT:'TTO',TN:'TUN',TR:'TUR',TM:'TKM',TC:'TCA',TV:'TUV',UG:'UGA',UA:'UKR',AE:'ARE',GB:'GBR',
        US:'USA',UM:'UMI',UY:'URY',UZ:'UZB',VU:'VUT',VE:'VEN',VN:'VNM',VI:'VIR',WF:'WLF',EH:'ESH',YE:'YEM',ZM:'ZMB',ZW:'ZWE'
    };
    return table[country] ? table[country] : 'FRA';
};
