(function( $ ) {

    /**
     * Version 20160213
     * 
     * Appelle le service DQE à partir du champ de saisie d'un téléphone
     * Options disponibles :
     *   - server: URL du serveur
     *   - country: code d'un pays précis (3 caractères) OU sélecteur jQuery pointant sur le champ pays
     *   - autocheck: lance la vérification du téléphone lorsqu'on quitte le champ téléphone (par défaut = true)
     *   - format: format de sortie du numéro de téléphone (0=chiffres, 1=avec séparateurs, 2=international basique, 3=international complet)
     *
     * Méthodes disponibles :
     *   - check(callback_function_name, [output_format]) : lance manuellement la validation du téléphone saisi et appelle callback_function_name avec les résultats de la validation
     * 
     * Evènements disponibles :
     *   - checking: se lance juste avant la vérification d'un téléphone
     *   - checked(data): se lance dès qu'un téléphone est validé. Cet évènement doit obligatoirement être implémenté si autocheck est à true car c'est alors la seule façon de récupérer le résultat de la validation

     *
     * @param {object} options Tableau associatif des options
     * @returns {jQuery}
     */
    $.fn.dqephone = function(options) {

        var myDQE = this;
        
        //On initialise le conteneur du champ et l'icône de statut s'il y en a (uniquement pour des champs Bootstrap)
        var myDQEContainer = myDQE.closest('div');
        var myDQEIcon = false;
        var myDQEIconMode = false;
        if (myDQEContainer.length) {
            myDQEIcon = myDQEContainer.find('span.glyphicon');
            if (myDQEIcon.length) myDQEIconMode = 'glyphicon';
            else myDQEIcon = myDQEContainer.find('i.fa');
            if (myDQEIcon.length) myDQEIconMode = 'fa';
            else myDQEIcon = false;
        }
        else myDQEContainer = false;
        
        var settings = $.extend({
            //Paramètres par défaut
            country: 'FRA',
            format: 0,
            license: ''
        }, options);
        myDQE.settings = settings;
        
        //On récupère les champs à partir de leur selecteur
        myDQE.server       = settings.server;
        myDQE.license      = settings.license;
        myDQE.countryField = settings.country.length === 3 ? false : $(settings.country);
        myDQE.autocheck    = settings.autocheck === undefined ? true : settings.autocheck;
        myDQE.country      = settings.country;
        myDQE.format       = settings.format;
        myDQE.asmx         = myDQE.server.toLowerCase().indexOf(".asmx") > -1;

        //Paramètres d'appel AJAX par défaut pour .net
        if (myDQE.asmx) {
            $.ajaxSetup({
                type: "POST",
                contentType: "application/json; charset=utf-8",
                data: "{}",
                processData: false,
                dataFilter: function(data) {
                    if (typeof (JSON) !== 'undefined' && typeof (JSON.parse) === 'function') data = JSON.parse(data);
                    else data = eval('(' + data + ')');
                    if (data.hasOwnProperty('d')) return data.d;
                    return data;
                }
            });

            $.ajaxPrefilter(function(options, originalOptions, jqXHR) {
                options.data = JSON.stringify(originalOptions.data);
            });
        }

        if (myDQE.server === 'jsonp') {
            myDQE.ajax = function(url, callback) {
                $.ajax({
                    url: url,
                    dataType: 'jsonp',
                    jsonp: 'callback',
                    success: function(data) {
                        callback(JSON.parse(data));
                    }
                });
            };
        }
        else {
            if (myDQE.server === 'cors') {
                myDQE.ajax = function(url, callback) {
                    $.ajax({
                        url: url,
                        method: 'GET',
                        dataType: 'text',
                        crossdomain: true,
                        success: function(data) {
                            callback(JSON.parse(data));
                        }
                    });
                };
            }
            else {
                myDQE.ajax = function (url, callback) {
                    $.ajax({
                        url: myDQE.server,
                        data: {url: url},
                        method: 'POST',
                        dataType: 'json',
                        success: function (data) {
                            callback(data);
                        }
                    });
                };
            }
        }
        
        myDQE.check = function(callback_function_name, output_format, complete_check) {
            var input = myDQE.val();
            if (input) {
                myDQE.trigger('checking');
                myDQE.bootstrapState("loading");

                var fmt = output_format ? output_format : myDQE.format;
                var country = myDQE.selected_country();
                

                var ko = {status: 0, state: 'error', response: 0};
                if (!input) {
                    myDQE.trigger('checked', [ko]);
                    myDQE.bootstrapState('error');
                    if (callback_function_name) window[callback_function_name](ko);
                    return;
                }

                var query = {fn: "TEL", Pays: country, Tel: input, Format: fmt};
                complete_check = parseInt(complete_check) === 1;
                if (complete_check) query["complete_check"] = 1;
                var url = myDQE.url(query);
                myDQE.ajax(url, function(data) {
                    if (!data || !data[1]) data = ko;
                    else {
                        data = data[1];
                        var ok = parseInt(data['IdError'], 10);
                        data = ok ? {status: 1, input: input, output: data['Tel'], state: 'success', response: data.IdError} :  ko;
                    }
                    myDQE.trigger('checked', [data]);
                    myDQE.bootstrapState(data.state);
                    if (callback_function_name) window[callback_function_name](data);
                });
            }
        };

        myDQE.isIE = function() {
            var ua = navigator.userAgent;
            return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1 || ua.indexOf('Edge/') > -1;
        };
        
        myDQE.clearBootstrapState = function() {
            if (myDQEContainer) myDQEContainer.removeClass('has-success').removeClass('has-warning').removeClass('has-error');
            if (myDQEIcon) {
                if (myDQEIconMode === 'glyphicon') myDQEIcon.removeClass('glyphicon-ok').removeClass('glyphicon-warning-sign').removeClass('glyphicon-remove');
                else myDQEIcon.removeClass('fa-check').removeClass('fa-warning').removeClass('fa-remove').removeClass('fa-spin').removeClass('fa-cog');
            }
        };
        
        myDQE.bootstrapState = function(state) {
            myDQE.clearBootstrapState();
            if (state) myDQEContainer.addClass("has-" + state);
            var signs, sign;
            if (myDQEIcon) {
                if (myDQEIconMode === 'glyphicon') {
                    //glyph icons
                    signs = {success: 'ok', warning: 'warning-sign', error: 'remove'};
                    sign = signs[state];
                    if (state) myDQEIcon.addClass("glyphicon-" + sign);
                }
                else {
                    //font-awesome
                    signs = {success: 'check', warning: 'warning', error: 'remove', loading: 'cog'};
                    sign = signs[state];
                    if (state) myDQEIcon.addClass("fa-" + sign);
                    if (state === 'loading' && !myDQE.isIE()) myDQEIcon.addClass("fa-spin");
                }
            }
        };

        myDQE.selected_country  = function() {
            if (myDQE.countryField) return this.convert_iso2(myDQE.countryField.val());
            return myDQE.country;
        };

        myDQE.removeAutocomplete = function(element) {
            if (element.data('ui-autocomplete')) {
                element.autocomplete("destroy");
                element.removeData("ui-autocomplete");
            }
        };

        myDQE.url = function(data) {
            var parameters = [];
            for (var key in data) {
                if (!data.hasOwnProperty(key) || key === "fn" || key === "server") continue;
                parameters.push(key + "=" + encodeURIComponent(data[key]));
            }

            return 'https://prod2.dqe-software.com/' + data["fn"] + "/?" + parameters.join("&") + "&Licence=" + myDQE.license;
        };

        if (myDQE.autocheck) {
            myDQE.on('blur', function() {
                myDQE.check();
            });
        }
        
        //On enlève les icônes affichant l'état lorsque le champ est modifié
        if (myDQEIcon) {
            myDQE.on("keypress", function() {
                myDQE.clearBootstrapState();
            });
        }

        myDQE.convert_iso2 = function(country) {
            var table = {
                AF:'AFG',AX:'ALA',AL:'ALB',DZ:'DZA',AS:'ASM',AD:'AND',AO:'AGO',AI:'AIA',AQ:'ATA',AG:'ATG',AR:'ARG',AM:'ARM',AW:'ABW',
                AU:'AUS',AT:'AUT',AZ:'AZE',BS:'BHS',BH:'BHR',BD:'BGD',BB:'BRB',BY:'BLR',BE:'BEL',BZ:'BLZ',BJ:'BEN',BM:'BMU',BT:'BTN',
                BO:'BOL',BA:'BIH',BW:'BWA',BV:'BVT',BR:'BRA',VG:'VGB',IO:'IOT',BN:'BRN',BG:'BGR',BF:'BFA',BI:'BDI',KH:'KHM',CM:'CMR',
                CA:'CAN',CV:'CPV',KY:'CYM',CF:'CAF',TD:'TCD',CL:'CHL',CN:'CHN',HK:'HKG',MO:'MAC',CX:'CXR',CC:'CCK',CO:'COL',KM:'COM',
                CG:'COG',CD:'COD',CK:'COK',CR:'CRI',CI:'CIV',HR:'HRV',CU:'CUB',CY:'CYP',CZ:'CZE',DK:'DNK',DJ:'DJI',DM:'DMA',DO:'DOM',
                EC:'ECU',EG:'EGY',SV:'SLV',GQ:'GNQ',ER:'ERI',EE:'EST',ET:'ETH',FK:'FLK',FO:'FRO',FJ:'FJI',FI:'FIN',FR:'FRA',GF:'GUF',
                PF:'PYF',TF:'ATF',GA:'GAB',GM:'GMB',GE:'GEO',DE:'DEU',GH:'GHA',GI:'GIB',GR:'GRC',GL:'GRL',GD:'GRD',GP:'GLP',GU:'GUM',
                GT:'GTM',GG:'GGY',GN:'GIN',GW:'GNB',GY:'GUY',HT:'HTI',HM:'HMD',VA:'VAT',HN:'HND',HU:'HUN',IS:'ISL',IN:'IND',ID:'IDN',
                IR:'IRN',IQ:'IRQ',IE:'IRL',IM:'IMN',IL:'ISR',IT:'ITA',JM:'JAM',JP:'JPN',JE:'JEY',JO:'JOR',KZ:'KAZ',KE:'KEN',KI:'KIR',
                KP:'PRK',KR:'KOR',KW:'KWT',KG:'KGZ',LA:'LAO',LV:'LVA',LB:'LBN',LS:'LSO',LR:'LBR',LY:'LBY',LI:'LIE',LT:'LTU',LU:'LUX',
                MK:'MKD',MG:'MDG',MW:'MWI',MY:'MYS',MV:'MDV',ML:'MLI',MT:'MLT',MH:'MHL',MQ:'MTQ',MR:'MRT',MU:'MUS',YT:'MYT',MX:'MEX',
                FM:'FSM',MD:'MDA',MC:'MCO',MN:'MNG',ME:'MNE',MS:'MSR',MA:'MAR',MZ:'MOZ',MM:'MMR',NA:'NAM',NR:'NRU',NP:'NPL',NL:'NLD',
                AN:'ANT',NC:'NCL',NZ:'NZL',NI:'NIC',NE:'NER',NG:'NGA',NU:'NIU',NF:'NFK',MP:'MNP',NO:'NOR',OM:'OMN',PK:'PAK',PW:'PLW',
                PS:'PSE',PA:'PAN',PG:'PNG',PY:'PRY',PE:'PER',PH:'PHL',PN:'PCN',PL:'POL',PT:'PRT',PR:'PRI',QA:'QAT',RE:'REU',RO:'ROU',
                RU:'RUS',RW:'RWA',BL:'BLM',SH:'SHN',KN:'KNA',LC:'LCA',MF:'MAF',PM:'SPM',VC:'VCT',WS:'WSM',SM:'SMR',ST:'STP',SA:'SAU',
                SN:'SEN',RS:'SRB',SC:'SYC',SL:'SLE',SG:'SGP',SK:'SVK',SI:'SVN',SB:'SLB',SO:'SOM',ZA:'ZAF',GS:'SGS',SS:'SSD',ES:'ESP',
                LK:'LKA',SD:'SDN',SR:'SUR',SJ:'SJM',SZ:'SWZ',SE:'SWE',CH:'CHE',SY:'SYR',TW:'TWN',TJ:'TJK',TZ:'TZA',TH:'THA',TL:'TLS',
                TG:'TGO',TK:'TKL',TO:'TON',TT:'TTO',TN:'TUN',TR:'TUR',TM:'TKM',TC:'TCA',TV:'TUV',UG:'UGA',UA:'UKR',AE:'ARE',GB:'GBR',
                US:'USA',UM:'UMI',UY:'URY',UZ:'UZB',VU:'VUT',VE:'VEN',VN:'VNM',VI:'VIR',WF:'WLF',EH:'ESH',YE:'YEM',ZM:'ZMB',ZW:'ZWE'
            };
            return table[country] ? table[country] : 'FRA';
        };
        
        return myDQE;
    };

}( jQuery ));
