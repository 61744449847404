
(function( $ ) {
    function WeStoryDqe() {
        this.selector = {
            email: '.ws-dqe-email',
            phone: '.ws-dqe-phone',
            country: '.ws-dqe-country',
            firstname: '.ws-dqe-firstname',
            lastname: '.ws-dqe-lastname',
            street: '.ws-dqe-street',
            compl: '.ws-dqe-compl',
            zip: '.ws-dqe-zip',
            city: '.ws-dqe-city'
        };
        // options
        this.options = {
            server: '/ajax/dqe',
            autocheck: true,
            suggest: true,
            license : ""

        };
        // init function
        this.init = function () {
            var params = this.getElements();
            this.createeOptions(params);
            //this.dqeEmail(params.$email);
            //this.dqePhone(params.$phone);
            //this.dqefirstname(params.$firstname);
            this.dqeAddress(params.address);
        };


        this.getElements = function () {
            var $email = $(this.selector.email);
            var $phone = $(this.selector.phone);
            var $country = $(this.selector.country);
            var $firstname = $(this.selector.firstname);
            var $lastname = $(this.selector.lastname);
            var $street = $(this.selector.street);
            var $compl = $(this.selector.compl);
            var $zip = $(this.selector.zip);
            var $city =  $(this.selector.city);
            return {
                $phone: $phone, $email: $email, $country: $country, $firstname: $firstname, $lastname: $lastname,
                address: {$country: $country, $street: $street, $compl: $compl, $city: $city, $zip: $zip}
            };
        };

        // update options
        this.createeOptions = function (params) {
            if(params.$country.length){
                this.options.country = this.selector.country;
            }
            if(params.$firstname.length){
                this.options.first_name = this.selector.firstname;
            }
            if(params.$lastname.length){
                this.options.last_name = this.selector.lastname;
            }
        };

        // dqe for emails
        this.dqeEmail = function ($email) {
            var count =0;
            var self = this;
            $email.each(function () {
                count++;
                var $myself = $(this);
                var attr = 'dqemail'+count;
                window[attr] = $myself.dqemail(self.options);
                window[attr].on('checking', function() {
                    self.errorHandler(false, $myself);
                });
                window[attr].on('checked', function (ui, data) {
                    $myself.parent().removeClass('has-error');
                    if(data.state === 'success' || data.state === "warning"){
                        self.errorHandler(false, $myself);
                    }else{
                        self.errorHandler(true, $myself);
                    }
                });
            });
        };

        // dqe for phone
        this.dqePhone = function ($phone) {
            var count =0;
            var self = this;
            $phone.each(function () {
                count++;
                var $myself = $(this);
                var attr = 'dqephone'+count;
                window[attr] = $myself.dqephone(self.options);
                window[attr].on('checking', function(t) {
                    self.errorHandler(false, $myself);
                });
                window[attr].on('checked', function (ui, data) {
                    $myself.parent().removeClass('has-error');
                    if(data.state === 'success'){
                        self.errorHandler(false, $myself);
                    }else{
                        self.errorHandler(true, $myself);
                    }
                });
            });
        };

        // dqe firstname
        this.dqefirstname = function ($firstname) {
            var count =0;
            var self = this;
            $firstname.each(function () {
                count++;
                var $myself = $(this);
                var attr = 'dqefirstname'+count;
                window[attr] = $myself.dqefirstname(self.options);
                window[attr].on('checking', function(t) {
                    self.errorHandler(false, $myself);
                });
                window[attr].on('checked', function (ui, data) {
                    $myself.parent().removeClass('has-error');
                    console.log(data);
                    if(data.state === 'success' || data.state === 'warning'){
                        self.errorHandler(false, $myself);
                    }else{
                        self.errorHandler(true, $myself);
                    }
                });
            });
        };

        this.dqeAddress = function(address) {
            var self = this;
            if(address.$street.length) {
                var options = self.options;
                options.single = self.selector.street;
                options.street = self.selector.compl;
                options.zip = self.selector.zip;
                options.city = self.selector.city;
                options.country = self.selector.country;
                window.dqeObject = address.$street.closest('form').dqe(options);

                window.dqeObject.on("single", function(source, element) {
                    var adresseValue = element.value;
                    adresseValue = adresseValue.substring(0, adresseValue.indexOf('|'));
                    address.$street.val(adresseValue);
                });
            }
        };

        // Error handler
        this.errorHandler = function (hasError, $element) {
            if($element.attr('data-form-type') === 'register-form' || $element.attr('data-form-type') === 'widget-form' ){
                if(hasError) {
                    var msg = 'Vous devez indiquer un email valide!';
                    if($element.parent().parent().find('.form-inputs.form-error').length) {
                        $element.parent().parent().find('.form-inputs.form-error').html(msg);
                    } else {
                        var $errorHtml = $('<div class="form-inputs form-error">'+msg+'</div>');
                        $element.parent().before($errorHtml);
                    }
                } else {
                    $element.parent().parent().find('.form-inputs.form-error').remove();
                }
            } else {
                if(hasError)
                    $element.parent().addClass('has-error');
                else
                    $element.parent().removeClass('has-error');
            }
        };
    }

    // create instance when document ready
    $(document).ready(function () {
       var wsDqe = new WeStoryDqe();
       wsDqe.init();
       wsDqeActions.init(wsDqe);
    });
}( jQuery ));
